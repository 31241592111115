import DataProductCard from '../components/DataProductCard';
import { useParams } from 'react-router-dom';
import { Loader } from '@entur/loader';
import { useQuery } from 'react-query';
import { getContainersForDomain } from '../API';
import { BannerAlertBox } from '@entur/alert';
import { findDomain } from '../hooks/domainUtils';
import HeaderNavigation from '../components/HeaderNavigation';
import React from 'react';
import CommonDomainlessPageHeader from '../components/CommonDomainlessPageHeader';
import AboutEnturSection from '../components/AboutEnturSection';
import CommonDomainlessPageFooter from '../components/CommonDomainlessPageFooter';
import HighlightedCardDQReport from '../components/HighlightedCardDQReport';
import { Heading2, Link as EnturLink, Paragraph } from '@entur/typography';

type DatahubDomain = Components.Schemas.DatahubDomain;

export type DomainsProp = {
    domains: DatahubDomain[];
};

const DataProductsPage = ({ domains }: DomainsProp) => {
    const { domainPathName } = useParams();
    let domain = findDomain(domains, domainPathName);
    const productsQuery = useQuery(['get-products', domain?.urn], () => getContainersForDomain(domain?.urn || ''));

    if (productsQuery.isLoading) {
        return <Loader style={{ paddingTop: '1.5rem' }}>Loading products..</Loader>;
    } else if (productsQuery.isError) {
        const error = productsQuery.error || 'An unknown error occurred';
        return <BannerAlertBox variant={'error'}>Failed to load data: {error}</BannerAlertBox>;
    } else if (!productsQuery.isSuccess) {
        return (
            <BannerAlertBox variant={'warning'}>
                Failed to load the data products. We apologise for any inconvenience this may have caused. Please try
                again shortly.
            </BannerAlertBox>
        );
    } else if (productsQuery.data.length === 0) {
        return (
            <>
                <HeaderNavigation domains={domains} useContrast={false} />
                <div style={{ padding: '2rem', display: 'flex', justifyContent: 'center' }}>
                    No data products found for the current domain.
                </div>
            </>
        );
    }

    return (
        <>
            <CommonDomainlessPageHeader />
            <AboutEnturSection />

            <div style={{ padding: '2rem', display: 'flex', justifyContent: 'center', minHeight: '70vh' }}>
                <div>
                    <Heading2 style={{ margin: '2.5rem 0' }}>Highlighted</Heading2>
                    <HighlightedCardDQReport />
                    <Heading2 style={{ margin: '2.5rem 0' }}>Data Products</Heading2>

                    {productsQuery.data.map((datahubContainer) => (
                        <DataProductCard
                            key={datahubContainer.name}
                            product={datahubContainer}
                            domain={domain!!}
                            maxChars={500}
                        />
                    ))}

                    <Heading2 style={{ paddingTop: '4rem' }}>Questions</Heading2>
                    <Paragraph>
                        Want help to find the things you are looking for? Send an email to{' '}
                        <EnturLink href="mailto:analytics-guild@entur.org">analytics-guild@entur.org</EnturLink>
                    </Paragraph>
                    <Paragraph>
                        Want to help us improve this site?{' '}
                        <EnturLink href="https://forms.gle/j4UV5c2CDTBU2nXp9" target="_blank">
                            Give us feedback
                        </EnturLink>
                    </Paragraph>
                </div>
            </div>
            <div>
                <CommonDomainlessPageFooter isContrast={true} />
            </div>
        </>
    );
};

export default DataProductsPage;
