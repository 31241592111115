import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@entur/tab';
import SchemaTab from './tabs/SchemaTab';
import SummaryTab from './tabs/SummaryTab';
import DocumentationTab from './tabs/DocumentationTab';
import BQDataPreviewTab from './tabs/BQDataPreviewTab';
import React from 'react';
import NeTExFilesTab from './tabs/NeTExFilesTab';
import GTFSFilesTab from './tabs/GTFSFilesTab';
import TimetableFilesDocumentationTab from './tabs/TimetableFilesDocumentationTab';

type DatahubDataset = Components.Schemas.DatahubDataset;

type DatasetTabsProps = {
    selectedDataset: DatahubDataset;
};

const DatahubDatasetTabs = ({ selectedDataset }: DatasetTabsProps) => {
    const projectName = selectedDataset.qualifiedName?.split('.')[0] || '';
    const datasetName = selectedDataset.qualifiedName?.split('.')[1] || '';
    const tableName = selectedDataset.qualifiedName?.split('.')[2] || '';

    function emptyTabPanel() {
        return <TabPanel>''</TabPanel>;
    }

    const datasetIsTimetables = selectedDataset.documentation === '_timetable_files';

    return (
        <Tabs>
            <TabList>
                <Tab disabled={datasetIsTimetables}>About</Tab>
                <Tab disabled={!selectedDataset.schema}>Schema</Tab>
                <Tab disabled={!selectedDataset.previewAvailable}>Preview</Tab>
                <Tab disabled={!datasetIsTimetables}>Download</Tab>
                <Tab className={'documentation-tab'}>Documentation</Tab>
            </TabList>
            <TabPanels>
                <SummaryTab datahubDataset={selectedDataset} />
                <SchemaTab schema={selectedDataset.schema} />
                {selectedDataset.previewAvailable ? (
                    <BQDataPreviewTab
                        project={projectName}
                        dataset={datasetName}
                        table={tableName}
                        schema={selectedDataset.schema}
                    />
                ) : (
                    emptyTabPanel()
                )}
                {datasetIsTimetables ? (
                    selectedDataset.urn.includes('netex') ? (
                        <NeTExFilesTab />
                    ) : (
                        <GTFSFilesTab />
                    )
                ) : (
                    emptyTabPanel()
                )}
                {datasetIsTimetables ? (
                    <TimetableFilesDocumentationTab />
                ) : (
                    <DocumentationTab entityHtmlDescription={selectedDataset.documentation || ''} />
                )}
            </TabPanels>
        </Tabs>
    );
};

export default DatahubDatasetTabs;
